import React from 'react'
import M_IconButton from '../utils/M_IconButton/M_IconButton'
import CloseIcon from '@mui/icons-material/Close'
import M_Select from '../utils/M_Select/M_Select'
import M_RadioButton from '../utils/M_RadioButton/M_RadioButton'
import './modalReservation.css'
import { M_CheckBox } from '../utils/M_CheckBox/M_CheckBox'

export default function FirstPage({ setOpenModal, setFirstPage, userSelectDic, curtMins, setUserSelectDic, handleRadioChange }) {

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value
        userSelectDic.set("Duration", selectedValue)
        setUserSelectDic(new Map(userSelectDic))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setFirstPage(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='buttonClose closeIconSticky'>
                <M_IconButton icon={<CloseIcon />} fontSize={'1'} onClick={() => {
                    setOpenModal(false)
                }} />
            </div>
            <div className='modalPrincipalDiv'>
                <div className='modalLeftDiv SecondPageLeft'>
                    <div className='modalTitle'> Reserva </div>
                    <div className='userDataFields'>
                        <div className='UserDataFieldsPadding'> CIF - Club Internacional de Foot-Ball </div>
                        <div className='UserDataFieldsPadding'> {userSelectDic.get("CourtName")}</div>
                    </div>
                    <div className='userDataFields'>
                        <M_RadioButton
                            label={"Torneio escada"}
                            options={[
                                { label: "Não", value: "nao" },
                                { label: "Sim", value: "sim" }
                            ]}
                            selectedValue={userSelectDic.get("LadderTournament") || ""}
                            onChange={(e) => {
                                handleRadioChange(e, "LadderTournament")
                            }}
                            required={true}
                        />
                    </div>
                    <div className='userDataFields'>
                        <div className='UserDataFieldsPadding'> {userSelectDic.get("DayWeek").substring(0, 3)}, {userSelectDic.get("Day")} {userSelectDic.get("Month")} {userSelectDic.get("Year")} </div>
                        <div className='UserDataFieldsPadding'> {userSelectDic.get("ReservationTime")} horas</div>
                        <div className='UserDataFieldsPadding'>
                            <M_Select
                                letterColor={"var(--color-black-light)"}
                                color={"var(--color-black-light)"}
                                label={"Duração"}
                                options={userSelectDic?.get("Partner") === 'sim' ? curtMins : [curtMins[0]]}
                                onChange={handleSelectChange}
                                value={userSelectDic.get("Duration")}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className='userDataFieldsSecond'>
                        {userSelectDic.get("Partner") === 'nao' ?
                            <>
                                <div className='rowFirstPage'>
                                    <div className='UserDataFieldsPadding fontWeightBold'> Subtotal: </div>
                                    <div className='UserDataFieldsPadding fontWeightBold'> {userSelectDic.get("CourtPrice")}€ </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className='UserDataFieldsPadding'>
                                        <span style={{ textTransform: 'initial' }}>
                                            O valor apresentado refere-se à <b>taxa de reserva e garante apenas a reserva solicitada, não cobre o pagamento completo da utilização do campo</b>.
                                        </span>
                                    </div>
                                    <div className='UserDataFieldsPadding'>
                                        <span style={{ textTransform: 'initial' }}>
                                            <b>O pagamento final deve ser concluído na portaria do CIF, no dia da reserva</b>, antes da utilização do Court.
                                        </span>
                                    </div>
                                    <div>
                                        <M_CheckBox label={"Aceito as condições de pagamento"} required />
                                    </div>
                                </div>
                            </>
                            :
                            <div className='rowFirstPage'>
                                <div className='UserDataFieldsPadding'> O montante da reserva será pago na secretaria no CIF. Pode consultar no botão das informações onde encontrará o regulamento com os valores aplicados. </div>
                            </div>
                        }
                    </div>
                    <div className='divModalSubmitButton'>
                        <button type="submit" className='buttonConfirm'>
                            Reservar
                        </button>
                    </div>
                </div>
                <div className='modalRightDivSecond'>
                    <img src="/Images/ModalImage.png" alt="modalImage" className='imgShadow' />
                </div>
            </div>
        </form>
    )
}