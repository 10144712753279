import React, { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BufferService } from '../utils/buffer.service'
import '../Cancellations/cancellations.css'
import LoadingContext from '../utils/loading.context'

export default function Rejectuser() {
    const ctx = useContext(LoadingContext)
    const navigate = useNavigate()
    const location = useLocation()
    const BackendPut = new BufferService('identity/updatePartner')
    const urlParam = decodeURIComponent(location.search)

    useEffect(() => {
        BackendPut.putQuery(urlParam).then(post => {
            if (post.name === 'AxiosError') {
                ctx.toastMessages('error', '', "Algo correu mal, tente novamente!")
                return
            }
            ctx.toastMessages('success', "O utilizador ficará como não sócio.")
        })
    }, [])

    return (
        <>
            <div style={{ marginTop: '25px' }} className='spanNoteCancelV2'>
                <h1 style={{ textTransform: 'inherit', color: 'green' }}>O utilizador ficará como não sócio.</h1>
            </div>
            <div className='divCancelButtonV2'>
                <button onClick={() => navigate("/")} className='buttonCancelV2'>
                    Confirmar
                </button>
            </div>
        </>
    )
}