import React, { useState, useEffect, useContext } from 'react'
import './modalReservation.css'
import { Dialog, DialogContent } from '@mui/material'
import FirstPage from './firstPage'
import SecondPage from './secondPage'
import { useMediaQuery } from '@mui/material'
import { BufferService } from '../utils/buffer.service'
import { isValidPhoneNumber } from 'libphonenumber-js'
import LoadingContext from '../utils/loading.context'

export default function ModalReservation({ openModal, setOpenModal, userSelectDic, setUserSelectDic, curtMins, setCurrentWeek }) {
    const media1250w = useMediaQuery('(max-width: 1250px)')
    const [firstPage, setFirstPage] = useState(true)
    const backendGet = new BufferService('identity/userrole')
    const [mbway, setMbway] = useState(false)
    const [crediCard, setCrediCard] = useState(false)
    const ctx = useContext(LoadingContext)

    const inputsModal = [
        { label: "Nome", type: "text", code: "Name" },
        {
            label: "Tipo de jogo", type: "radio", code: "TypeGame", options: [
                { label: "Singulares", value: "Single" },
                { label: "Pares", value: "Pares" }
            ]
        },
        { label: "Apelido", type: "text", code: "Surname" },
        { label: "Jogador2", type: "text", code: "Player2" },
        { label: "Email", type: "email", code: "Email" },
        { label: "Jogador3", type: "text", code: "Player3" },
        { label: "Telemóvel", type: "tel", code: "Phone" },
        { label: "Jogador4", type: "text", code: "Player4" },
        {
            label: "Sócio", type: "radio", code: "Partner", options: [
                { label: "Não", value: "nao" },
                { label: "Sim", value: "sim" }
            ]
        },
    ]

    //utils to check number
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const handlePhoneNumberChange = (value) => {
        setPhoneNumberValid(isValidPhoneNumber(value))
    }

    useEffect(() => {
        ctx.setIsLoad(true)
        backendGet.get().then(resPost => {
            if (resPost.name === 'AxiosError') {
                ctx.setIsLoad(false)
                ctx.toastMessages('error', '', "Algo correu mal, tente novamente!")
                setTimeout(function () {
                    window.location.reload()
                }, 2000)
                return
            }
            let tempValues = {
                'Name': resPost.User.FirstName,
                'Surname': resPost.User.LastName,
                'Partner': (resPost.User.Partner === true) ? "sim" : "nao",
                'Nif': resPost.User.VatNumber,
                'Phone': resPost.User.PhoneNumber,
                'Email': resPost.User.Email
            }
            Object.keys(tempValues).forEach(key => {
                userSelectDic.set(key, tempValues[key])
            })
            if (userSelectDic.get("Phone") !== undefined) {
                setPhoneNumberValid(isValidPhoneNumber(userSelectDic.get("Phone")))
            }
            ctx.setIsLoad(false)
        })
        // eslint-disable-next-line
    }, [])

    const submitReservation = (event, index) => {
        if (index === "Phone" || (event && event.target)) {
            const value = index === "Phone" ? event : event.target.value
            userSelectDic.set(index, value)
            setUserSelectDic(new Map(userSelectDic))
        }
    }

    const handleCloseMessageBoard = () => {
        setOpenModal(false)
        setFirstPage(false)
        setCrediCard(false)
        setMbway(false)
    }

    const handleRadioChange = (e, code) => {
        const selectedValue = e.target.value
        userSelectDic.set(code, selectedValue)
        setUserSelectDic(new Map(userSelectDic))
    }

    const maxWidthw = media1250w ? "auto" : "1155px"
    const maxWidthh = media1250w ? "auto" : userSelectDic.get("Partner") === 'nao' ? "680px" : "635px"

    return (
        <Dialog
            sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { minWidth: '247px', width: "100%", maxWidth: maxWidthw, height: maxWidthh } } }}
            open={openModal} onClose={handleCloseMessageBoard}
        >
            <DialogContent>
                {firstPage ?
                    <FirstPage
                        setOpenModal={setOpenModal}
                        setFirstPage={setFirstPage}
                        userSelectDic={userSelectDic}
                        curtMins={curtMins}
                        setUserSelectDic={setUserSelectDic}
                        handleRadioChange={handleRadioChange}
                    />
                    :
                    <SecondPage
                        phoneNumberValid={phoneNumberValid}
                        handlePhoneNumberChange={handlePhoneNumberChange}
                        setFirstPage={setFirstPage}
                        setCurrentWeek={setCurrentWeek}
                        inputsModal={inputsModal}
                        submitReservation={submitReservation}
                        setOpenModal={setOpenModal}
                        handleRadioChange={handleRadioChange}
                        userSelectDic={userSelectDic}
                        mbway={mbway}
                        setMbway={setMbway}
                        crediCard={crediCard}
                        setCrediCard={setCrediCard}
                    />}
            </DialogContent>
        </Dialog >
    )
}