import React, { useEffect } from 'react'
import M_Button from '../utils/M_Button/M_Button'
import { BufferService } from '../utils/buffer.service'
import { isValidPhoneNumber } from 'libphonenumber-js'

export default function EditProfile({ ctx, inputs, renderInput, userAccount, editProfile, phoneNumberValid, setUserAccount, setPhoneNumberValid }) {
    const backendPut = new BufferService('identity/updateprofile')
    const backendGet = new BufferService('identity/userrole')

    useEffect(() => {
        ctx.setIsLoad(true)
        backendGet.get().then(resPost => {
            if (resPost.name === 'AxiosError') {
                ctx.setIsLoad(false)
                ctx.toastMessages('error', '', "Algo correu mal, tente novamente!")
                return
            }
            let tempValues = {
                'Name': resPost.User.FirstName,
                'Surname': resPost.User.LastName,
                'Partner': (resPost.User.Partner === true) ? "sim" : "nao",
                'PartnerNumber': resPost.User.PartnerNumber,
                'Nif': resPost.User.VatNumber,
                'Phone': resPost.User.PhoneNumber,
                'Email': resPost.User.Email,
                'WasPartner': resPost.User.WasPartner
            }
            Object.keys(tempValues).forEach(key => {
                userAccount.set(key, tempValues[key])
            })
            setUserAccount(new Map(userAccount))
            if (userAccount.get("Phone") !== undefined) {
                setPhoneNumberValid(isValidPhoneNumber(userAccount.get("Phone")))
            }
            ctx.setIsLoad(false)
        })
    }, [])

    const backendPostProfile = (e) => {
        ctx.setIsLoad(true)
        e.preventDefault()

        let tempValues = {
            'FirstName': userAccount.get("Name"),
            'LastName': userAccount.get("Surname"),
            'Partner': (userAccount.get("Partner") === 'sim') ? true : false,
            'PartnerNumber': userAccount.get("PartnerNumber"),
            'VatNumber': userAccount.get("Nif"),
            'Email': userAccount.get("Email"),
            'UserName': userAccount.get("Email"),
            'PhoneNumber': userAccount.get("Phone"),
        }

        backendPut.put(tempValues).then(response => {
            if (response.name === 'AxiosError') {
                ctx.setIsLoad(false)
                ctx.toastMessages("error", "", "Algo correu mal, tente novamente!")
                return
            }
            ctx.setIsLoad(false)
            ctx.toastMessages("success", "", "Perfil editado com sucesso!")
        })
    }

    return (
        <form onSubmit={backendPostProfile} style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <div className='modalInputFieldsProfile'>
                    <div className='left-columnProfile'>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[0])}
                        </div>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[1])}
                        </div>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[3])}
                        </div>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[2])}
                        </div>
                    </div>
                    <div className='right-columnProfile'>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[4])}
                        </div>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[6])}
                        </div>
                        <div className='singleInputsSecondPageProfile'>
                            {renderInput(inputs[7])}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '68.81px', display: 'flex', marginTop: '15px', marginTop: '15px', borderTop: '1px solid var(--color-grey)', alignItems: 'center', justifyContent: 'center' }}>
                <M_Button
                    height={40}
                    width={10}
                    bold
                    label={"Confirmar"}
                    labelColor={'var(--color-white)'}
                    type={"submit"}
                    onClick={() => { }}
                    borderRadius={15}
                    hoverEffect
                    backgroundColor={"var(--color-green)"}
                    borderColor={"var(--color-green)"}
                    disabled={editProfile ? !phoneNumberValid : false}
                />
            </div>
        </form>
    )
}